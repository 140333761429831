<template>
  <v-app class="inventory">
    <header class="pl-16 v-app-bar">
      <div class="v-toolbar__content transparent" style="height: 56px;">
        <v-row>
          <div elevation="0" class="pl-0 header-icon">
            <v-icon class="icon scan"></v-icon>
          </div>
        </v-row>
      </div>
    </header>
    <Menu />
    <v-main>
      <v-row class="col-12 pa-0 ma-0 scan" >
        <v-col class="primary col-12 pa-0">
          <Scan @search-by-ean-code="searchByEanCode" v-if="e6 === 0" />
        </v-col>
      </v-row>
      <v-stepper v-model="e6" vertical>
        <v-row class="col-12 pa-0 ma-0 scan" >
          <v-col class="col-12 p-2">
          <v-text-field
            label="Localizador / Código"
            text
            clearable
            ref="textEan"
            v-model="ean" 
            @keyup.enter="searchByEanCode(ean)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-stepper-step :complete="e6 > 1" step="1">
          <span class="primary--text ">Escanea el árticulo a inventariar</span>
          <small>Modelo y color</small>
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-card class="mb-4 elevation-0" v-if="ean !== ''">
            <v-col class="d-flex justify-start px-0 col-12">
              <v-img :src="foto" class="pr-4 mr-2" max-height="80" max-width="90" contain> </v-img>
              <v-card-title class="product d-flex pa-0 flex-column justify-start align-start">
                <p class="text-subtitle-1 mb-0 font-weight-bold primary--text pa-0 text-uppercase text-truncate">{{ articulo.modelo }}</p>
                <p class=" mb-0 accent--text pa-0 subtitle-1 d-flex">{{ ean }}</p>
                <p class="font-weight-light mb-0 pa-0 caption d-flex text-truncate">{{ articulo.color }}</p>
              </v-card-title>
              <v-btn icon top right absolute class="out">
                <v-icon @click="init()">mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-card>
          <v-btn color="primary" @click="e6 = 2" tile v-if="ean !== ''">
            Siguiente
          </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 2" step="2">
          <span class="primary--text">Comprobar stock</span>
          <small>Unidades por tallas</small>
        </v-stepper-step>
        <v-stepper-content step="2">
          <v-card class="mb-2 elevation-0">
            <v-row class="d-flex justify-start ma-0 stock-table">
              <v-col class="d-flex pa-0 flex-column align-center jusitify-start flex-grow-0" v-for="(card, i) in tallas" :key="i">
                <p class="px-4 py-1 text-body-1 font-weight-medium primary--text mb-0 text-center" v-text="card.talla"></p>
                <p class="pa-1 mb-0 text-center grey--text text--darken-2" v-text="card.stock"></p>
                <v-text-field :ref="card.tallaInterna" type="number" class="pt-0 text-h6 text-center" label="Stock"></v-text-field>
              </v-col>
            </v-row>
          </v-card>
          <v-btn color="primary" @click="e6 = 3;">
            Continuar
          </v-btn>
          <v-btn color="primary" text @click="e6 = 1;">
            Cancelar
          </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 3" step="3" class="primary--text">
          <span class="primary--text ">Confirmar inventario</span>
        </v-stepper-step>
        <v-stepper-content step="3">
          <v-card class="mb-3 elevation-0">
            ¿Deseas registrar el inventario?
          </v-card>
          <v-btn color="secondary" @click="e6 = 1; guardarInventario()">
            Guardar
          </v-btn>
          <v-btn color="primary" text @click="e6 = 1;">
            Cancelar
          </v-btn>
        </v-stepper-content>
      </v-stepper>
      <v-snackbar v-model="snackbar" :multi-line="multiLine" :color="snackColor">
        {{ snackText }}

        <template v-slot:action="{ attrs }">
          <v-btn fab text v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import Menu from '../components/Menu.vue';
import Scan from '../components/Shop/scan.vue';
import { TiendaApiService } from '../api/TiendaApiService';
const tiendaApiService = new TiendaApiService();
import { InventarioApiService } from '../api/InventarioApiService';
const inventarioApiService = new InventarioApiService();

export default Vue.extend({
  name: 'ModificacionStock',
  components: {
    Menu,
    Scan,
  },
  data() {
    return {
      e6: 0,
      snackbar: false,
      multiLine: true,
      snackColor: '',
      snackText: '',
      opId: '',
      opUid: '',
      ean: '',
      foto: '',
      tallas: [],
      articulo: {},
    };
  },
  computed: {
    cambiotienda () {
      return this.$store.getters.getIdTienda;
    },
    cambiotiendaUid () {
      return this.$store.getters.getOpuid;
    },
  },
  watch: {
    cambiotienda (value) {
      this.opId = value
      this.init()
    },
    cambiotiendaUid (value) {
      this.opUid = value
      this.init()
    }
  },
  mounted() {
    this.opId = this.$store.getters.getIdTienda
    this.opUid = this.$store.getters.getOpuid
    this.init()
    // this.searchByEanCode('370000050030')
  },
  methods: {
    init() {
      this.articulo = {}
      this.e6 = 0
      this.ean = ''
      this.foto = ''
      this.tallas = []
      this.$refs.textEan.focus()
    },
    searchByEanCode(ean) {
      // alert(ean)
      if (ean.length > 0) {
        this.getProductBy(ean);
      } else {
        this.snackbar = true;
        this.snackColor = 'red darken-2';
        this.snackText = 'No se ha podido escanear el código';
      }
    },
    getProductBy(ean) {
      // var filtro = {}
      // Object.assign(filtro, { ean: ean })
      tiendaApiService
        .getArticuloByCod(ean)
        .then((data) => {
          console.log(data)
          if (data.articulo !== null) {
            this.articulo = data.articulo
            this.e6 = 1
            this.ean = ean
            this.getFoto()
            this.getStock()
          } else {
            this.snackbar = true
            this.snackColor = 'red darken-2'
            this.snackText = 'No se ha encontrado el articulo'
            this.init()
          }
        })
        .catch((error) => {
          this.init()
          this.snackbar = true
          this.snackColor = 'red darken-2'
          this.snackText = 'Ha ocurrido un error'
          console.log('tiendaApiService - findArticulos ' + error)
        });
    },
    getFoto() {
      if (this.articulo.fotos.length > 0) {
        this.foto = this.articulo.fotos[0].url;
      }
    },
    getStock() {
      let stock, ean
      this.tallas = []
      for (var x=0; x < this.articulo.tallas.length; x++) {
        if (this.articulo.tallas[x].eans.length > 0) {
          ean = this.articulo.tallas[x].eans[0].ean
        } else {
          ean = ''
        }
        if (this.articulo.tallas[x].stocks.length > 0) {
          stock = this.articulo.tallas[x].stocks.find(element => element.id.id_op === this.opId)
          if (stock) {
            this.tallas.push({
                            talla: this.articulo.tallas[x].talla, 
                            tallaInterna: this.articulo.tallas[x].tallaInterna,
                            stock: stock.unidades,
                            ean: ean
                            })
          } else {
            this.tallas.push({
                            talla: this.articulo.tallas[x].talla, 
                            tallaInterna: this.articulo.tallas[x].tallaInterna,
                            stock: 0,
                            ean: ean
                            })
          }
        } else {
          this.tallas.push({
                            talla: this.articulo.tallas[x].talla, 
                            tallaInterna: this.articulo.tallas[x].tallaInterna,
                            stock: 0,
                            ean: ean
                            })
        }
      }
    },
    guardarInventario() {
      let inventario = []
      inventario.push(this.opUid)
      inventario.push(this.$store.state.userInfo.user)
      for (var x=0; x < this.tallas.length; x++) {
        if (this.$refs[this.tallas[x].tallaInterna][0].internalValue) {
          if (this.$refs[this.tallas[x].tallaInterna][0].internalValue - this.tallas[x].stock !== 0) {
            inventario.push({
                            tallaInterna: this.tallas[x].tallaInterna,
                            tallaExterna: this.tallas[x].talla,
                            unidades: this.$refs[this.tallas[x].tallaInterna][0].internalValue - this.tallas[x].stock,
                            ean: this.tallas[x].ean,
                            idArtic: this.articulo.id
                            })
          }
        }
      } 
      inventarioApiService.enviarModificacionStock(inventario)
        .then(() => {
          this.snackbar = true
          this.snackColor = 'green'
          this.snackText = 'Inventario registrado con éxito'
        })
        .catch(error => {
          this.snackbar = true
          this.snackColor = 'red darken-2'
          this.snackText = 'Error al enviar el inventarío'
          console.log('tiendaApiService - enviarInventario ' + error)
        })
      this.init()
    }
  },
});
</script>
