<template>
  <v-app class="entradas-salidas">
    <header class="pl-16 v-app-bar">
      <div class="v-toolbar__content" style="height: 56px;">
        <v-row>
          <div elevation="0" class="pl-0 header-icon">
            <v-icon class="icon scan"></v-icon>
          </div>
          <v-btn class="back white" icon large>
            <router-link :to="{ name: 'InventariosControl'}">
              <v-icon class="icon atras"></v-icon>
            </router-link>
          </v-btn>
        </v-row>
      </div>
    </header>
    <Menu />

    <v-main class="pb-15 inventory2 margin-top pt-15">
      <div v-if="cargando" class="mt-15 text-center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <div v-else>
        <v-card title="inventario.nombre" :subtitle="inventario.fechaInicio + ' - ' + inventario.fechaFin " :text="inventario.notas">
          {{ inventario.clicode + '-' + inventario.nombre }}
          {{ inventario.fechaInicio + '-' + inventario.fechaFin }}
          <v-card-actions>
            <v-btn v-if="estadoInventario === 1" @click="iniciarInventario()">Iniciar</v-btn>
            <v-btn v-if="estadoInventario === 2" @click="finalizarInventario()">Finalizar</v-btn>
            <v-btn v-if="estadoInventario === 3" @click="enviarInventario()">Enviar</v-btn>
            <v-btn v-if="estadoInventario === 2" @click="cancelarInventario()">Cancelar</v-btn>
            <v-btn v-if="estadoInventario === 3" @click="reabrirInventario()">Reabrir</v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-for="(lector, index) in lectores" :key="index" >
          {{ lector[0] }} - {{ lector[1] }} - {{ lector[2] }}
          
        </v-card>
      </div>
    </v-main>
  </v-app>
</template>
<script lang="ts">
import Vue from 'vue';
import Menu from '../components/Menu.vue';
import { InventarioApiService } from '../api/InventarioApiService';
const inventarioApiService = new InventarioApiService();

export default Vue.extend({
  name: 'InventarioGestion',
  components: {
    Menu,
  },
  props: {
    inventario: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      cargando: false,
      estadoInventario: 0, // diferentes estados en los que se encuentra el inventario 
      usuario: this.$store.state.userInfo.user.substring(0, 50).toLowerCase(),
      lectores: []
    };
  },
  computed: {

  },
  watch: {

  },
  created() {
    if (this.inventario === null) {
      this.$router.push({name : 'InventariosControl' })
    }
    this.inervalLecturas = setInterval(() => this.getLectoresEstado(), 3000)
  },
  destroyed() {
    clearInterval(this.inervalLecturas)
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.setEstadoInventario()
    },
    setEstadoInventario() {
      if (this.inventario.fechaInicio === '' || this.inventario.fechaInicio === null || this.inventario.fechaInicio === undefined) {
        this.estadoInventario = 1 //sin inciar
      } else if ((this.inventario.fechaInicio !== '' && this.inventario.fechaInicio !== null && this.inventario.fechaInicio !== undefined) &&
        (this.inventario.fechaFin === '' || this.inventario.fechaFin === null || this.inventario.fechaFin === undefined)) {
        this.estadoInventario = 2 // iniciado sin finalizar -> pendiente
      } else if (this.inventario.fechaInicio !== '' && this.inventario.fechaInicio !== null && this.inventario.fechaInicio !== undefined &&
      this.inventario.fechaFin !== '' && this.inventario.fechaFin !== null && this.inventario.fechaFin !== undefined) {
        this.estadoInventario = 3 
      } else {
        // error estado no contemplado
        this.$root.$emit('mostrarAlerta', 'Error estado inventario desconocido -> contactar con soporte', 'error')
        console.log('inventario', this.inventario)
      }
    },
    iniciarInventario() {
      let inventarioAux = {
        msicOp: this.inventario.opuid,
        msicUsuario: this.usuario
      }
      inventarioApiService.iniciarInventario(inventarioAux)
        .then(data => {
            console.log(data)
            this.estadoInventario = 2
            this.inventario.fechaInicio = data.msicFechaInicio
          })
        .catch(error => {
          this.$root.$emit('mostrarAlerta', 'Error al iniciar el inventario', 'error')
          console.log(error)
        })
    }, 
    finalizarInventario() {
      this.$root.$confirm.open('Finalizar', '¿Deseas finalizar el invetario?', { color: 'primary' }).then((confirm) => {
        if (confirm) {
          let inventarioAux = {
            msicOp: this.inventario.opuid,
            msicUsuario: this.usuario
          }
          inventarioApiService.finalizarInventario(inventarioAux)
            .then(data => {
                console.log(data)
                this.estadoInventario = 3
                this.inventario.fechaInicio = data.msicFechaInicio
                this.inventario.fechaFin = data.msicFechaFin
              })
            .catch(error => {
              this.$root.$emit('mostrarAlerta', 'Error al finalizar el inventario', 'error')
              console.log(error)
            })
        }
      })
    },
    enviarInventario() {
      this.$root.$confirm.open('Enviar', '¿Deseas enviar el inventario a central? Esta operación no se puede cancelar', { color: 'primary' }).then((confirm) => {
        if (confirm) {
          this.cargando = true
          let inventarioAux = {
            msicOp: this.inventario.opuid,
            msicUsuario: this.usuario
          }
          inventarioApiService.enviarInventarioCompleto(inventarioAux)
            .then(data => {
                this.cargando = false
                console.log(data)
                this.$root.$emit('mostrarAlerta', 'El inventario se ha enviado con éxito', 'green')
                this.$router.push({ name: 'InventariosControl' });
              })
            .catch(error => {
              this.cargando = false
              this.$root.$emit('mostrarAlerta', 'Error al enviar el inventario', 'error')
              console.log(error)
            })
        }
      })
    },
    cancelarInventario() {
      this.$root.$confirm.open('Cancelar', '¿Deseas cancelar el inventario? Todas las lecturas se borraran', { color: 'primary' }).then((confirm) => {
        if (confirm) {
          let inventarioAux = {
            msicOp: this.inventario.opuid,
            msicUsuario: this.usuario
          }
          inventarioApiService.cancelarInventario(inventarioAux)
            .then(data => {
                console.log(data)
                this.estadoInventario = 1
                this.inventario.fechaInicio = ''
              })
            .catch(error => {
              this.$root.$emit('mostrarAlerta', 'Error al cancelar el inventario', 'error')
              console.log(error)
            })
        }
      })
    },
    reabrirInventario() {
      this.$root.$confirm.open('Reabrir', '¿Deseas reabrir el inventario?', { color: 'primary' }).then((confirm) => {
        if (confirm) {
          let inventarioAux = {
            msicOp: this.inventario.opuid,
            msicUsuario: this.usuario
          }
          inventarioApiService.reabrirInventario(inventarioAux)
            .then(data => {
                console.log(data)
                this.estadoInventario = 2
                this.inventario.fechaFin = ''
              })
            .catch(error => {
              this.$root.$emit('mostrarAlerta', 'Error al reabrir el inventario', 'error')
              console.log(error)
            })
        }
      })
    },
    getLectoresEstado() {
      inventarioApiService.getLectoresEstado(this.inventario.opuid)
        .then(data => {
          this.lectores = data
        })
        .catch(error => {
          this.lectores = []
          this.$root.$emit('mostrarAlerta', 'Error al estado lecturas', 'error')
          console.log(error)
        })
    }
  },
});
</script>
