import axios from 'axios';

const URL_API_TPV_COMS = process.env.VUE_APP_URL_API_TPV_COMS;
const URL_API_ALBARAN = process.env.VUE_APP_URL_API_ALBARAN;

export class AlbaranApiService {
 
  getAlbaranesCab(opUid) {
    let config = {
      headers: {'Authorization': opUid, 'Accept' : 'application/json'}
    };
    const url = `${URL_API_TPV_COMS}entradas`;
    return axios
      .get(url,config)
      .then(function(response) {
        return response.data;
       
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  getBultos(opUid) {
    let config = {
      headers: {'Authorization': opUid, 'Accept' : 'application/json'}
    };
    const url = `${URL_API_TPV_COMS}entradas-bultos`;
    return axios
      .get(url,config)
      .then(function(response) {
        return response.data;
       
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  getAlbaranDetalle(opUid, uid) {
    let config = {
      headers: {'Authorization': opUid, 'Accept' : 'application/json'}
    };
    const url = `${URL_API_TPV_COMS}entradas/${uid}`;
    return axios
      .get(url,config)
      .then(function(response) {
        return response.data;
       
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  getBultoDetalle(opUid, uid) {
    let config = {
      headers: {'Authorization': opUid, 'Accept' : 'application/json'}
    };
    const url = `${URL_API_TPV_COMS}entradas-bultos/${uid}`;
    return axios
      .get(url,config)
      .then(function(response) {
        return response.data;
       
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  enviarEntrada(entrada) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_ALBARAN}enviarEntrada`;
    return axios
      .post(url, entrada, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  enviarSalida(salida) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_ALBARAN}enviarSalida`;
    return axios
      .post(url, salida, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
}