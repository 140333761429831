<template>
  <v-app>
    <header class="pl-16 v-app-bar" style="background: #fff;">
      <div class="v-toolbar__content" style="height: 56px;">
        <v-btn class="back white" icon large>
            <router-link :to="{ name: 'Entradas'}">
              <v-icon class="icon atras"></v-icon>
            </router-link>
          </v-btn>
          <v-row>
            <div elevation="0" class="pl-0 header-icon">
              <v-icon class="icon caja"></v-icon>
            </div>
          </v-row>
         
        <!-- <Header /> -->
        <div class="d-flex align-center mr-auto pr-2 pl-14">
          <v-text-field
            label="Buscar albarán"
            text
            clearable
            hide-details
            v-model="codigoAlbaran" 
            class="pt-0"
            >
          </v-text-field>
          <v-btn class="icon search" icon large> </v-btn>
          </div>
        </div>
    </header>
    <Menu />
     
    <v-main class="order margin-top">
      <div v-if="cargando" class="mt-15 text-center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>

      <v-list class="mt-3" subheader two-line v-else>
        <v-list-item v-for="albaran in albaranesFiltrado" :key="albaran.albaran_id" @click="verDetalle(albaran)">
          <v-list-item-avatar>
            <v-icon class="accent" dark>
              mdi-archive
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="primary--text" >
              <span v-text="albaran.albaran_codigo"></span> <span v-text="albaran.origen_cod + '-' + albaran.origen_nombre"></span> <span class="font-weight-black " v-text="albaran.albaran_referencia"></span>
            </v-list-item-title>
            <v-list-item-subtitle class="accent--text" v-text="albaran.albaran_fecha"></v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn icon>
              <v-icon :color="albaran.color" v-text="albaran.icon"></v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider></v-divider>
      </v-list>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import Menu from '../../components/Menu.vue';

import { AlbaranApiService } from '../../api/AlbaranApiService';
const albaranApiService = new AlbaranApiService();

export default {
  name: 'Albaranes',
  components: {
    Menu
  },
  data() {
    return {
      cargando: false,
      codOdt: '',
      opUid: this.$store.getters.getOpuid,
      albaranes: [],
      albaranesFiltrado: [],
      // files: [
      //   { icon: 'mdi-information', color: 'grey lighten-1', subtitle: '3/03/2014', title: 'SSCC3', number: '0028434690000', bold: '255732' },
      //   { icon: 'mdi-check-circle', color: 'success', subtitle: '3/03/2014', title: 'SSCC4', number: '0028434690000', bold: '255732' },
      //   { icon: 'mdi-check-circle', color: 'success', subtitle: '3/03/2014', title: 'SSCC5', number: '0028434690000', bold: '255732' },
      // ],
      codigoAlbaran: ''
    };
  },
  computed: {
    cambiotienda() {
      return this.$store.getters.getOpuid;
    },
  },
  watch: {
    cambiotienda(value) {
      this.opUid = value;
      this.init();
    },
    codigoAlbaran(value) {
      this.filtarAlbaran(value)
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.albaranes = this.getAlbaranesByOpuid()
    },
    getAlbaranesByOpuid() {
      this.cargando = true
      albaranApiService.getAlbaranesCab(this.opUid)
        .then((data) => {
          console.log(data)
          this.cargando = false
          if (data.data) {
            this.albaranes = data.data
            this.albaranesFiltrado = this.albaranes
          }
          
        })
        .catch((error) => {
          this.cargando = false
          this.$root.$emit('mostrarAlerta', 'Error al obtener albaranes', 'error')
          console.log('albaranApiService - getAlbaranesCab ' + error)
        });
    },
    filtarAlbaran() {
      if (this.codigoAlbaran && this.codigoAlbaran.length > 0) {
        this.albaranesFiltrado = this.albaranes.filter(item =>{
          return (item.albaran_codigo.indexOf(this.codigoAlbaran) >= 0 || 
                  item.origen_cod.indexOf(this.codigoAlbaran) === 0 || 
                  item.albaran_referencia.toUpperCase().indexOf(this.codigoAlbaran.toUpperCase()) >= 0)
        })
      } else {
        this.albaranesFiltrado = this.albaranes
      }
    },
    verDetalle(albaran) {
      // La funcionalidad de albaran en curso no tiene sentido, si salen de la pantalla se pierden las lecturas, no importa

      // if (this.$store.getters.getAlabaranEnCurso && this.$store.getters.getAlabaranEnCurso !== null) {
      //   if (this.$store.getters.getAlabaranEnCurso.albaran_uid !== albaran.albaran_uid) {
      //     this.$root.$confirm.open('Conexión', 'Ya hay un albaran en curso, se borraran las lecturas ¿Desea continuar?', { color: 'primary' }).then((confirm) => {
      //       if (confirm) {
      //         this.$store.commit('setBultoAlbaranEnCurso', albaran)
      //         this.$router.push({ path: '/AlbaranDetalle/' + albaran.albaran_uid });
      //       }
      //     })
      //   } else {
      //     this.$store.commit('setBultoAlbaranEnCurso', albaran)
      //     this.$router.push({ path: '/AlbaranDetalle/' + albaran.albaran_uid });
      //   }
      // } else {
      //   this.$store.commit('setBultoAlbaranEnCurso', albaran)
      //   this.$router.push({ path: '/AlbaranDetalle/' + albaran.albaran_uid });
      // }
      this.$store.commit('setBultoAlbaranEnCurso', albaran)
      this.$router.push({ path: '/AlbaranDetalle/' + albaran.albaran_uid });
    }
  },
};
</script>