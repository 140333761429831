import axios from 'axios';

const URL_API_INVENTARIO = process.env.VUE_APP_URL_API_INVENTARIO;

export class InventarioApiService {
 
  addLinea(linea) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_INVENTARIO}addLinea`;
    return axios
      .post(url, linea, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  addLineas(lineas) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_INVENTARIO}addLineas`;
    return axios
      .post(url, lineas, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  getUltimasLineasByOp(opUid, usuario) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_INVENTARIO}getUltimasLineasByOp/${opUid}/${usuario}`;
    return axios
      .get(url, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  getResumenInventario(opUid, usuario) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_INVENTARIO}getResumenInventario/${opUid}/${usuario}`;
    return axios
      .get(url, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  deleteLinea(lineaId) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_INVENTARIO}deleteLinea/${lineaId}`;
    return axios
      .delete(url, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  updateLinea(linea) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_INVENTARIO}updateLinea`;
    return axios
      .put(url, linea,  config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        console.log(error)
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  enviarInventario(inventario) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_INVENTARIO}enviarInventario`;
    return axios
      .post(url, inventario, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  borrarInventario(opUid, usuario) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_INVENTARIO}borrarInventario/${opUid}/${usuario}`;
    return axios
      .delete(url, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  enviarModificacionStock(inventario) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_INVENTARIO}enviarModificacionStock`;
    return axios
      .post(url, inventario, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  getInventarioEnCurso(opUid) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_INVENTARIO}getInventarioEnCurso/${opUid}`;
    return axios
      .get(url, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  getInventarios(opUids) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_INVENTARIO}getInventarios/${opUids}`;
    return axios
      .get(url, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  iniciarInventario(inventario) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_INVENTARIO}iniciarInventario`;
    return axios
      .put(url, inventario, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  cancelarInventario(inventario) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_INVENTARIO}cancelarInventario`;
    return axios
      .put(url, inventario, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  finalizarInventario(inventario) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_INVENTARIO}finalizarInventario`;
    return axios
      .put(url, inventario, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  enviarInventarioCompleto(inventario) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_INVENTARIO}enviarInventarioCompleto`;
    return axios
      .post(url, inventario, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  reabrirInventario(inventario) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_INVENTARIO}reabrirInventario`;
    return axios
      .put(url, inventario, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  getLectoresEstado(opuid) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_INVENTARIO}getLectoresEstado/${opuid}`;
    return axios
      .get(url, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  marcarLineasInventarioCompleto(opUid, usuario) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_INVENTARIO}marcarLineasInventarioCompleto/${opUid}/${usuario}`;
    return axios
      .post(url, null, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
}