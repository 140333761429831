<template>
  <v-app class="">
    <header class="pl-16 v-app-bar">
      <div class="v-toolbar__content" style="height: 56px;">
        <v-row>
          <div elevation="0" class="pl-0 header-icon">
            <v-icon class="icon scan"></v-icon>
          </div>
        </v-row>
      </div>
    </header>
    <Menu />

    <v-main class="pb-15 inventory2 margin-top pt-15">
      <div v-if="cargando" class="mt-15 text-center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <div v-else>
        <v-list subheader two-line>
          <v-subheader class="text-uppercase"></v-subheader>
          <v-list-item v-for="inventario in inventariosOp" :key="inventario.nombre" :to="{name: 'InventarioGestion', params:{inventario: inventario}}">
            <v-list-item-avatar>
              <v-icon class="accent" dark>
                mdi-archive
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="primary--text">
                <span v-text="inventario.clicode + '-' + inventario.nombre"></span> 
                <span v-text="inventario.fechaInicio"></span>
                <span v-text="inventario.fechaFin"></span>
              </v-list-item-title>
              <!-- <v-list-item-subtitle class="accent--text" v-text="inventario.fechaFin"></v-list-item-subtitle> -->
            </v-list-item-content>

            <!-- <v-list-item-action>
              <v-btn icon>
                <v-icon :color="bulto.color" v-text="bulto.icon"></v-icon>
              </v-btn>
            </v-list-item-action> -->
          </v-list-item>

          <v-divider></v-divider>
        </v-list>
      </div>
    </v-main>
  </v-app>
</template>
<script lang="ts">
import Vue from 'vue';
import Menu from '../components/Menu.vue';
// import { TiendaApiService } from '../api/TiendaApiService';
import { InventarioApiService } from '../api/InventarioApiService';
// const tiendaApiService = new TiendaApiService();
const inventarioApiService = new InventarioApiService();

export default Vue.extend({
  name: 'InventariosControl',
  components: {
    Menu,
  },
  data() {
    return {
      cargando: false,
      inventariosOp: []
    };
  },
  computed: {

  },
  watch: {

  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.inventariosOp = []
      this.getInventariosTiendas()
    },
    getInventariosTiendas() {
      this.cargando = true
      let opUids = this.$store.state.userInfo.listaTiendas.map(a => a.opuid).join(',')
      inventarioApiService.getInventarios(opUids)
        .then(data => {
            this.cargando = false
            console.log(data, 11)
            this.$store.state.userInfo.listaTiendas.forEach(tienda => {
              let inventario = []
              if (data) {
                inventario = data.filter(item => item.msicOp === tienda.opuid)
              }
              if (inventario.length > 0) {
                console.log(inventario)
                this.inventariosOp.push({
                  clicode: tienda.clicode,
                  nombre: tienda.nombre,
                  fechaInicio: inventario[0].msicFechaInicio,
                  fechaFin: inventario[0].msicFechaFin,
                  notas: inventario[0].msicNotas,
                  opuid: inventario[0].msicOp,
                })
              } else {
                this.inventariosOp.push({
                  clicode: tienda.clicode,
                  nombre: tienda.nombre,
                  fechaInicio: '',
                  fechaFin: '',
                  notas: '',
                  opuid: tienda.opuid,
                })
              }
            });
          })
        .catch(error => {
          this.cargando = false
          this.$root.$emit('mostrarAlerta', 'Error al obtener inventarios', 'error')
          console.log(error)
        })
    }
  },
});
</script>
