import axios from 'axios';
const URL_API_VACACION = process.env.VUE_APP_URL_API_VACACION;

export class VacacionApiService {
  /**
   * Devuelve todas las vacaciones de una persona
   * @return Vacacion[]
   */
  getVacacionByPersonaId(mspersonaid) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = URL_API_VACACION + 'getVacacionByPersonaId/' + mspersonaid;
    return axios
      .get(url, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  /**
   * Devuelve todas las vacaciones de una persona, filtradas por mes y anho
   * @return Pais[]
   */
  getVacacionByMonthYearAndPersonaId(mes, anho, mspersonaid) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = URL_API_VACACION + 'getVacacionByMonthYearAndPersonaId/' + mes + '/' + anho + '/' + mspersonaid;
    return axios
      .get(url, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  getCountVacacionByYearAndPersonaId(vacacion) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_VACACION}getCountVacacionByYearAndPersonaId`;
    return axios
      .post(url, vacacion, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  /**
   * Inserta una Vacacion
   * @return response respuesta de la insercion
   */
  createVacacion(fecha, mspersonaid, mspersonauid, tipo) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = URL_API_VACACION + 'createVacacion';
    var data = {
      msvacacionfecha: fecha,
      msvacaciontipo: tipo,
      msvacaciontipostr: 'Vacacion normal',
      persona: {
        mspersonaid: mspersonaid,
        mspersonauid: mspersonauid
      }
    };
    return axios
      .post(url, data, config)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  // deleteVacacion (msvacacionid) {
  //   const url = URL_API_VACACION + 'deleteVacacion/' + msvacacionid
  //   return axios
  //     .delete(url)
  //     .then(function (response) {
  //       return response.data
  //     })
  //     .catch(function (error) {
  //       throw error
  //     })
  // }

  deleteVacacion(msvacacionid) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    let data = {
      msvacacionid: msvacacionid
    };
    const url = URL_API_VACACION + 'deleteVacacion';
    return axios
      .post(url, data, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
}
